@import '~antd/dist/antd.css';

body, html {
  overflow: auto !important;
}


.zuzaluLogoBox {
  width: 48px;
  height: 48px;


}

.zuzaluLogoBox svg {
  width: 48px;
  height: 48px;


}